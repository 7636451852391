import React from 'react';
import ViewInstallationsAsAdmin from './view_installation_as_admin'
import ViewValuations from './view_valuations'
import AddInstallation from './add_installation'
import AddValuation from './add_valuation'
import Login from './login'
import { Container, Nav, Navbar, Dropdown } from 'react-bootstrap';
import Cookies from "js-cookie";
import HoverControlledDropdown from './components/HoverControlledDropdown';
import { Link, withRouter } from 'react-router-dom'
import './App.css'

import {
  HashRouter as Router,
  Switch,
  Route
} from "react-router-dom";

export function Main() {
  const MyRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        return <Component {...props} />;
      }}
    />
  );
  return (
    <>
      <Router>
        <Navbar collapseOnSelect expand="sm" bg="dark" variant="dark">
          <Container>
            <Navbar.Brand>
              <img
                alt=""
                src="https://okna.srv22208.microhost.com.pl/logo_okmar.jpg"
                width="70"
                height="30"
                className="d-inline-block align-top"
              />{' '}
              </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto">
                <Nav.Item><Nav.Link className="nav-text" href="/#/zamowienia/dodaj">Dodaj zamówienie</Nav.Link></Nav.Item>
                  <Nav.Item>
                    <HoverControlledDropdown className="px-1">
                      <Dropdown.Toggle id="dropdown-button-dark-example" variant="dark">
                        Administracja
                      </Dropdown.Toggle>

                      <Dropdown.Menu variant="dark">
                      <Dropdown.Item to="/zamowienia/administracja/Dabrowa" as={Link}>
                          Dąbrowa Tarnowska
                      </Dropdown.Item>
                      <Dropdown.Item to="/zamowienia/administracja/Szczucin" as={Link}>
                          Szczucin
                      </Dropdown.Item>
                      </Dropdown.Menu>
                    </HoverControlledDropdown>
                  </Nav.Item>
                <Nav.Item><Nav.Link className="nav-text" href="/#/wyceny/dodaj">Dodaj reklamację</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link className="nav-text" href="/#/wyceny/przegladaj">Reklamacja</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link className="nav-text" href="/new_okna.db">{Cookies.get("Rights") === "admin" ? "Kopia bazy" : ""}</Nav.Link></Nav.Item>
                <Nav.Item><Nav.Link className="nav-text" href="/#/zaloguj">{(Cookies.get("Nick") == null) ? 'Zaloguj' : Cookies.get("Nick") + ' - zaloguj jako ktoś inny'}</Nav.Link></Nav.Item>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <Switch>
          <MyRoute path="/zamowienia/dodaj" component={AddInstallation} />
          <MyRoute path="/zamowienia/administracja/:city" component={ViewInstallationsAsAdmin} />
          <MyRoute path="/wyceny/dodaj" component={AddValuation} />
          <MyRoute path="/wyceny/przegladaj" component={ViewValuations} />
          <MyRoute path="/zaloguj" component={Login} />
          <MyRoute component={Login} />
        </Switch>
      </Router>
    </>
  );
}

export default withRouter(Main);
