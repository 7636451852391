import { Form, Button, Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom'
import axios from "axios";
import Cookies from "js-cookie";

export function AddInstallation() {
  var curr = new Date();
  curr.setDate(curr.getDate());
  var date = curr.toISOString().substr(0, 10);
  const fill_texts_dict = {

    "NAME": { "label": "Nazwisko i imie", "placeholder": "", "error_text": "", "data_type": "TEXT", "value": "" },
    "ADDRESS": { "label": "Adres", "placeholder": "", "error_text": "", "data_type": "TEXT", "value": "" },
    "PRODUCTS_NAME": { "label": "Sprzedany prod", "placeholder": "", "error_text": "", "data_type": "TEXT", "value": "" },
    "ORDER_NUMBER": { "label": "Nr zamówienia", "placeholder": "", "error_text": "", "data_type": "TEXT", "value": "" },
    "PHONE_NUMBER": { "label": "Numer telefonu", "placeholder": "", "error_text": "", "data_type": "TEXT", "value": "" },
    "ADVANCE": { "label": "Zadatek", "placeholder": "", "error_text": "", "data_type": "REAL", "value": 0 },
    "PRICE": { "label": "Cena", "placeholder": "", "error_text": "", "data_type": "REAL", "value": 0 },
    "INVOICE_NUMBER": { "label": "Nr faktury", "placeholder": "", "error_text": "", "data_type": "TEXT", "value": "" },
    "ADDITIONAL_INFORMATIONS": { "label": "Dodatkowe info", "placeholder": "", "error_text": "", "data_type": "TEXT", "value": "" },
    "COMPLAINT": { "label": "Reklamacja", "placeholder": "", "error_text": "", "data_type": "TEXT", "value": "" },
    //  "WHO_ADDED": { "label": "Kto dodał", "placeholder": "", "error_text": "", "data_type": "TEXT", "value": "" },
    //  "CITY": { "label": "Miasto", "placeholder": "", "error_text": "", "data_type": "TEXT", "value": "" },
    "ORDER_DATE": { "label": "Zamówione", "placeholder": "", "error_text": "", "data_type": "DATE", "value": date },
    "CLEARED_DATE": { "label": "Montaż", "placeholder": "", "error_text": "", "data_type": "DATE", "value": "" },
    "RECEIVE_PROTOCOL": { "label": "Protokół", "placeholder": "", "error_text": "", "data_type": "TEXT", "value": "" },
  };
  const select_elements_dict = {
    "DELIVERY": { "label": "Rodzaj dostawy", "placeholder": "", "error_text": "", "data_type": "TEXT", "elements": [" ", "Bez montażu z transportem", "Z montażem", "Odbiór osobisty"], "value": "Odbiór osobisty" },
    "INVOICE_CREATED": { "label": "Faktura wypisana", "placeholder": "", "error_text": "", "data_type": "TEXT", "elements": [" ", "Tak", "Nie całkowicie", "Nie"], "value": "" },
    "MATERIALS_AVALIABLE": { "label": "Materiały", "placeholder": "", "error_text": "", "data_type": "TEXT", "elements": [" ", "Nie zamówione", "Zamówione", "Na stanie"], "value": "" },
    "ORDER_CLEARED": { "label": "Zakończone", "placeholder": "", "error_text": "", "data_type": "TEXT", "elements": [" ", "Tak", "Nie"], "value": "" },
    "CITY": { "label": "Miasto", "placeholder": "", "error_text": "", "data_type": "TEXT", "elements": [" ", "Szczucin", "Dabrowa"], "value": "Dabrowa" },
  };


  let handleSubmit = event => {
    event.preventDefault();
    AddToDatabase()
  }

  function AddToDatabase() {
    let json = {}
    for (var key in fill_texts_dict) {
      if (fill_texts_dict.hasOwnProperty(key)) {
        json[key] = fill_texts_dict[key]["value"]
      }
    }
    for (var key in select_elements_dict) {
      if (select_elements_dict.hasOwnProperty(key)) {
        json[key] = select_elements_dict[key]["value"]
      }
    }
    if (Cookies.get("Rights") !== null || Cookies.get("Rights") !== undefined || Cookies.get("Rights") !== "") {
      let name = Cookies.get("Nick");
      json["WHO_ADDED"] = name[0].toUpperCase() + name.substring(1);
    }
    else
      json["WHO_ADDED"] = "";
      if (Cookies.get("Rights") === "szczucin_sales" && Cookies.get("Nick") !== "tomek"){
        json["CITY"] = "Szczucin"
      } else if (Cookies.get("Rights") !== "admin" && Cookies.get("Nick") !== "tomek"){
        json["CITY"] = "Dabrowa"
      }
    axios.post(`https://okna.srv22208.microhost.com.pl/add_installation.php`,
      json,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    ).then(response => {
      if (response.status !== 200) {
          alert("Nie dodano!")
      }
      else if (response.status === 200) {
          alert("Dodano!")
          window.location.reload(false);
      }
    })
    .catch((error) => {
      if (error.status !== 200) {
          alert("Nie dodano!")
      }
    })
  }

  function FillTextElement(element_key) {
    let control_by_type = <Form.Control as="textarea" rows={1} type="text" placeholder={fill_texts_dict[element_key]["placeholder"]} defaultValue=' ' onChange={(e) => { fill_texts_dict[element_key]["value"] = e.target.value; }} />
    if (element_key === "ADDITIONAL_INFORMATIONS" || element_key === "PRODUCTS_NAME" || element_key === "ADDRESS" || element_key === "NAME") {
      control_by_type = <Form.Control as="textarea" rows={3} type="text" placeholder={fill_texts_dict[element_key]["placeholder"]} defaultValue=' ' onChange={(e) => { fill_texts_dict[element_key]["value"] = e.target.value; }} />
    }
    else if (fill_texts_dict[element_key]["data_type"] === "REAL") {
      control_by_type = <Form.Control type='number' step="0.1" min='0' max='999999999' defaultValue='0' placeholder={fill_texts_dict[element_key]["placeholder"]} onChange={(e) => { fill_texts_dict[element_key]["value"] = e.target.value; }} />
    }
    else if (fill_texts_dict[element_key]["data_type"] === "DATE") {
      if (element_key === 'ORDER_DATE') {
        var curr = new Date();
        curr.setDate(curr.getDate());
        var date = curr.toISOString().substr(0, 10);
        control_by_type = <Form.Control type='date' value={date} placeholder={fill_texts_dict[element_key]["placeholder"]} onChange={(e) => { fill_texts_dict[element_key]["value"] = e.target.value; }} />
      }
      else
        control_by_type = <Form.Control type='date' placeholder={fill_texts_dict[element_key]["placeholder"]} onChange={(e) => { fill_texts_dict[element_key]["value"] = e.target.value; }} />
    }

    return (
      <>
        <Form.Group controlId={element_key}>
          <Form.Label>{fill_texts_dict[element_key]["label"]}</Form.Label>
          {control_by_type}
          <Form.Text className="text-muted">{fill_texts_dict[element_key]["error_text"]}</Form.Text>
        </Form.Group>
      </>
    );
  }

  function SelectElement(element_key) {
    if (element_key === "CITY" && Cookies.get("Rights") !== "admin" && Cookies.get("Nick") !== "tomek"){
      return (<></>);
    } else {
      return (
        <>
          <Form.Group controlId={element_key}>
            <Form.Label>{select_elements_dict[element_key]["label"]}</Form.Label>
            <Form.Control as="select"
              onChange={(e) => {
                select_elements_dict[element_key]["value"] = e.target.value;
              }}
            >
              {
                select_elements_dict[element_key]["elements"].map((element) => {
                  select_elements_dict[element_key]["value"] = element
                  return <option selected={true}>{element}</option>
                }
                )
              }
            </Form.Control>
          </Form.Group>
        </>
      );
    }

  }

  let elements = []
 
  for (var key in fill_texts_dict) {
    if (key==="ADDRESS") {
        if (fill_texts_dict.hasOwnProperty(key)) {
            elements.push(FillTextElement(key))
        }
        if (select_elements_dict.hasOwnProperty('DELIVERY')) {
            elements.push(SelectElement('DELIVERY'))
        }
    }else{
        if (fill_texts_dict.hasOwnProperty(key)) {
            elements.push(FillTextElement(key))
        } 
    }
   
}

for (var key in select_elements_dict) {
    if(key!=='DELIVERY') {
        if (select_elements_dict.hasOwnProperty(key)) {
            elements.push(SelectElement(key))
        }
    }
   
}
  return (
    <>
      <link
        rel="stylesheet"
        href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css"
        integrity="sha384-9aIt2nRpC12Uk9gS9baDl411NQApFmC26EwAOH8WgZl5MYYxFfc+NcPb1dKGj7Sk"
        crossorigin="anonymous"
      />
      <div className="px-md-5 pt-md-4">
        <Form>
          <Row>
            {elements.map((elem) => (
              <Col className="m-0 col-12 col-md-6 col-lg-4"> {elem} </Col>
            ))}
          </Row>
          <Button variant="primary" type='submit' onClick={handleSubmit}>Dodaj</Button>
        </Form>
      </div>
    </>
  );
}

export default withRouter(AddInstallation);
