import React, { useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { withRouter } from "react-router-dom";
import { Form, Button, Row } from 'react-bootstrap';

function SingIn({ history }) {
  const [message, setMessage] = useState("");
  const [login, setLogin] = useState("");
  const [pass, setPass] = useState("");

  const readLogin = (e) => {
    setLogin(e.target.value);
  };
  const readPass = (e) => {
    setPass(e.target.value);
  };
  const tryLogin = (e) => {
    e.preventDefault();
    setLogin("");
    setPass("");
    LogIn(login, pass);
  };

  const LogIn = async (login, pass) => {
    await axios
      .get(
        `https://okna.srv22208.microhost.com.pl/login.php?nick=` + login + `&pass=` + pass
      )
      .then((response) => {
        Cookies.set("Rights", response.data);
        Cookies.set("Nick", login);
        if (response.data === "admin" || response.data === "sales" || response.data === "readonly") {
          history.push("/zamowienia/administracja/Dabrowa");
        }
        else if(response.data === "szczucin_sales"){
          history.push("/zamowienia/administracja/Szczucin");
        }
        else {
          setMessage("Logowanie nieudane");
        }
      })
      .catch((err) => {
        //console.log(err.response);
        setMessage("Błędne dane");
      });
  };
  return (
    <>
      <link
        rel="stylesheet"
        href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css"
        integrity="sha384-9aIt2nRpC12Uk9gS9baDl411NQApFmC26EwAOH8WgZl5MYYxFfc+NcPb1dKGj7Sk"
        crossorigin="anonymous"
      />

      <div>
        <Form onSubmit={tryLogin}>
          <Row className="m-2">
            <Form.Group className="m-2" >
              <Form.Label className="m-2">Nick</Form.Label>
              <Form.Control className="m-2" type="text" value={login} onChange={readLogin} />
              <Form.Label className="m-2">Haslo</Form.Label>
              <Form.Control className="m-2" type="password" value={pass} onChange={readPass} />
              <Button className="m-2" variant="primary" type='submit'>Zaloguj</Button>
            </Form.Group>
          </Row>
        </Form>
      </div>
    </>
  );
}
export default withRouter(SingIn);
